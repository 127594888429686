import { RawLocation } from 'vue-router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { User, userRoles } from '@/models/User';
import { getStatusLabel } from '@/support/ReportStatus';
import { SkillTypes } from '@/models/Skill';
import { PermissionSlug } from '@/support/PermissionSlug';
import { onHoldReasonLabels } from '@/models/OnHoldReason';

@Component<Planning>({})
export default class Planning extends Vue {
  public $pageTitle = 'Planning';

  protected isLoading = true;

  protected userRoles = userRoles;

  protected isBureauManagerButNotPlanningAdmin = (
    ! this.$store.state.isServiceOrganization && this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles) && ! this.user.is_planner
  );

  protected isPlanningAdmin = (
    (this.$store.state.isServiceOrganization && this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles))
    || (! this.$store.state.isServiceOrganization && this.user.is_planner)
  );

  protected get isServiceLoket() {
    return this.$store.state.Auth.hasRole('serviceloket');
  }

  protected dashboardItems: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/planning',
      icon: {
        key: 'dashboard',
      },
      visible: ! this.$store.state.isServiceOrganization,
    },
    {
      title: 'Lang Lopende Dossiers',
      link: '/planning',
      icon: {
        key: 'dashboard',
      },
      visible: this.isPlanningAdmin && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Onderzoeken',
      link: '/planning/onderzoeken',
      icon: {
        key: 'dashboard',
      },
      visible: this.isPlanningAdmin && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Opname',
      link: '/planning/opname',
      icon: {
        key: 'dashboard',
      },
      visible: this.isPlanningAdmin && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Zelfopname',
      link: '/planning/zelfopname?status=planning&filter=zelfopname',
      icon: {
        key: 'dashboard',
      },
      visible: this.$store.state.isServiceOrganization,
    },
  ];

  protected items: SidemenuItem[] = [
    {
      title: 'Mededelingen',
      link: '/planning/mededelingen',
      icon: {
        key: 'notification_important',
      },
      visible: this.$store.state.isServiceOrganization,
    },
    {
      title: 'Planningstool starten',
      link: '/planning-tool',
      icon: {
        key: 'timelapse',
      },
      visible: this.$store.state.isServiceOrganization && ! this.isServiceLoket,
    },
    {
      title: 'Aanpassingen bureau',
      link: '/planning/aanpassingen-bureau',
      icon: {
        key: 'list',
      },
      visible: this.$store.state.isServiceOrganization,
    },
    {
      title: 'Projecten',
      link: '/projecten',
      icon: {
        key: 'folder_shared',
      },
      visible: this.$store.state.isServiceOrganization && ! this.isServiceLoket && this.canListProjects,
    },
    // {
    //   title: 'Afgesproken uren',
    //   link: '/planning/agreed-hours',
    //   icon: {
    //     key: 'timelapse',
    //   },
    //   visible: this.user.is_planner,
    // },
    // {
    //   title: 'Beschikbaarheid opvragen',
    //   link: '/planning/availability-request',
    //   icon: {
    //     key: 'date_range',
    //   },
    //   visible: this.$store.state.isServiceOrganization,
    // },
    {
      title: 'Deskundige lijst ',
      link: '/planning/block-users',
      icon: {
        key: 'supervisor_account',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
    {
      title: 'Logboek instellingen',
      link: '/planning/settings-log',
      icon: {
        key: 'storage',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
    {
      title: 'BAS import tool planning',
      link: '/planning/excel-uploader',
      icon: {
        key: 'cloud_upload',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
    {
      title: 'Instellingen',
      link: '/planning/settings',
      icon: {
        key: 'settings_applications',
      },
      visible: this.isPlanningAdmin && this.user.is_planner,
    },
  ];

  protected immaterieelItems: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/planning/immaterieel',
      icon: {
        key: 'dashboard',
      },
      visible: true,
    },
    {
      title: 'Immaterieel planningstool starten',
      link: { name: 'planning-tool-immaterieel', params: { isImmaterial: 'true' } },
      icon: {
        key: 'timelapse',
      },
      visible: true,
    },
    {
      title: 'Beschikbaarheid JZ',
      link: '/planning/availability-jz',
      icon: {
        key: 'event',
      },
      visible: true,
    },
    {
      title: 'JZ gepland',
      link: '/planning/reports?status=specials_planned',
      icon: {
        key: 'view_list',
      },
      visible: true,
    },
    {
      title: getStatusLabel('no_answer'),
      link: '/planning/plannable-reports?planning_status=no_answer&filter=ims',
      icon: {
        key: 'phone_disabled',
      },
      visible: true,
    },
    {
      title: 'Teruggezet Planning IMS',
      link: '/planning/reports?status=back_to_planning_ims',
      icon: {
        key: 'view_list',
      },
      visible: true,
    },
    {
      title: 'Productie per type',
      link: '/planning/planning-type-statistics-ims',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
  ];

  protected vesNulmetingItems: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/planning/ves-nulmeting',
      icon: {
        key: 'dashboard',
      },
      visible: true,
    },
    {
      title: 'VES nulmeting planningstool starten',
      link: { name: 'planning-tool-ves-nulmeting' },
      icon: {
        key: 'timelapse',
      },
      visible: true,
    },
    {
      title: getStatusLabel('no_answer'),
      link: '/planning/plannable-reports?planning_status=no_answer&filter=ves',
      icon: {
        key: 'event',
      },
      visible: true,
    },
    {
      title: getStatusLabel('vervolgopname'),
      link: '/planning/plannable-reports?status=back_to_planning&planning_status=vervolgopname&filter=ves',
      icon: {
        key: 'youtube_searched_for',
      },
      visible: true,
    },
    {
      title: 'Productie per type',
      link: '/planning/planning-type-statistics-ves',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
    {
      title: 'Overzicht annuleringen',
      link: '/planning/planning-cancelled-statistics-ves',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
  ];

  protected availabilityItems: SidemenuItem[] = [
    {
      title: `Beschikbaarheid ${this.$store.state.isServiceOrganization ? 'Zaakbegeleiders' : 'Deskundigen'}`,
      link: '/planning/availability',
      icon: {
        key: 'event',
      },
      visible: this.isPlanningAdmin || this.isServiceLoket,
    },
    {
      title: 'Beschikbaarheid Deskundigen',
      link: { name: 'organization-availability', params: { readOnly: 'true' } },
      icon: {
        key: 'event',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
    {
      title: 'Beschikbaarheidslog',
      link: '/planning/availability-log',
      icon: {
        key: 'storage',
      },
      visible: this.isPlanningAdmin,
    },
  ];

  protected agendaItems: SidemenuItem[] = [
    {
      title: 'Week planning',
      link: '/planning/week-planning',
      icon: {
        key: 'date_range',
      },
      visible: this.isPlanningAdmin || this.isBureauManagerButNotPlanningAdmin,
    },
    {
      title: 'Agenda\'s Deskundigen en ZBs',
      link: '/planning/availability-planner',
      icon: {
        key: 'date_range',
      },
      visible: this.isPlanningAdmin || this.isBureauManagerButNotPlanningAdmin || this.isServiceLoket || this.$store.state.Auth.hasRole(this.userRoles.PlanningRoles),
    },
    {
      title: 'Bedrijfsagenda\'s',
      link: '/planning/organization-planning',
      icon: {
        key: 'calendar_view_day',
      },
      visible: this.isPlanningAdmin || this.isBureauManagerButNotPlanningAdmin || this.$store.state.Auth.hasRole(this.userRoles.PlanningRoles),
    },
  ];

  protected statisticsItemsPlanning: SidemenuItem[] = [
    {
      title: 'Ingepland per medewerker',
      link: '/planning/production-statistics',
      icon: {
        key: 'show_chart',
      },
    },
  ]

  protected statisticsItems: SidemenuItem[] = [
    {
      title: 'Ingepland per medewerker',
      link: '/planning/production-statistics',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin && this.user.is_planner,
    },
    {
      title: 'On-hold per medewerker',
      link: '/planning/production-statistics-on-hold',
      icon: {
        key: 'pan_tool',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin && this.user.is_planner,
    },
    {
      title: 'Mutatie OHW',
      link: '/planning/production-statistics-incomming',
      icon: {
        key: 'perm_data_setting',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
    {
      title: 'Taakstelling per Bureau',
      link: '/planning/planning-statistics',
      icon: {
        key: 'business',
      },
      visible: this.isPlanningAdmin,
    },
    {
      title: `Ingepland per ${this.$store.state.isServiceOrganization ? 'Zaakbegeleider' : 'Deskundige'}`,
      link: '/planning/production-statistics-experts',
      icon: {
        key: 'contact_mail',
      },
      visible: this.isPlanningAdmin,
    },
    {
      title: 'Productie per medewerker',
      link: '/planning/planning-employee-statistics',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin && this.user.is_planner,
    },
    {
      title: 'Productie per type',
      link: '/planning/planning-type-statistics',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
    {
      title: 'Overzicht annuleringen',
      link: '/planning/planning-cancelled-statistics',
      icon: {
        key: 'show_chart',
      },
      visible: this.$store.state.isServiceOrganization && this.isPlanningAdmin,
    },
  ];

  protected werkbakken: SidemenuItem[] = [
    {
      title: getStatusLabel('planning'),
      link: '/planning/reports?status=planning&planning_workflow=standaard',
      icon: {
        key: 'web',
      },
    },
    {
      title: getStatusLabel('back_to_planning'),
      link: '/planning/reports?status=back_to_planning&planning_workflow=standaard',
      icon: {
        key: 'web',
      },
    },
    {
      title: getStatusLabel('unplanned'),
      link: '/planning/plannable-reports?planning_status=unplanned&planning_workflow=standaard',
      icon: {
        key: 'add_ic_call',
      },
    },
    {
      title: getStatusLabel('no_answer'),
      link: '/planning/plannable-reports?planning_status=no_answer&planning_workflow=standaard&filter=default',
      icon: {
        key: 'phone_disabled',
      },
    },
    {
      title: getStatusLabel('not_needed_anymore'),
      link: '/planning/plannable-reports?planning_status=not_needed_anymore&planning_workflow=standaard',
      icon: {
        key: 'cancel',
      },
    },
    {
      title: 'Intermediair',
      link: '/planning/reports?planning_workflow=intermediair&status=planning',
      icon: {
        key: 'work',
      },
      visible: this.isPlanningAdmin,
    },
    {
      title: 'Vastgoed',
      link: '/planning/reports?planning_workflow=vastgoed&status=planning',
      icon: {
        key: 'apartment',
      },
      visible: this.isPlanningAdmin,
    },
    {
      title: 'Immaterieel',
      link: '/planning/reports?filter=immaterieel&status=planning',
      icon: {
        key: 'accessible',
      },
      visible: this.isPlanningAdmin,
    },
  ];

  protected onHoldWerkbakken: SidemenuItem[] = [
    {
      title: 'Alles',
      link: '/planning/reports?status=planning_on_hold&planning_workflow=standaard',
      icon: {
        key: 'pan_tool',
      },
    },
    // WERKBAKKEN: ON HOLD REASONS / Planning On-Hold
    ...Object.entries(onHoldReasonLabels).map((onHoldReasonLabelEntry) => {
      const [onHoldReasonUuid, onHoldReasonLabel] = onHoldReasonLabelEntry;
      return {
        title: `${onHoldReasonLabel}`,
        link: `/planning/reports?status=planning_on_hold&planning_workflow=standaard&on_hold_reasons=["${onHoldReasonUuid}"]`,
        icon: {
          key: 'pan_tool',
        },
      };
    }),
  ]

  protected planningBureau: SidemenuItem[] = [
    {
      title: getStatusLabel('vervolgopname'),
      link: '/planning/reports?planning_status=vervolgopname',
      icon: {
        key: 'youtube_searched_for',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles)),
    },
    {
      title: getStatusLabel('aanvullend_onderzoek'),
      link: '/planning/reports?planning_status=aanvullend_onderzoek',
      icon: {
        key: 'zoom_in',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles)),
    },
    {
      title: getStatusLabel('nader_onderzoek'),
      link: '/planning/reports?planning_status=nader_onderzoek',
      icon: {
        key: 'zoom_out_map',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles)),
    },
    {
      title: getStatusLabel('heropname'),
      link: '/planning/reports?planning_status=heropname',
      icon: {
        key: 'zoom_out',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles)),
    },
  ];

  protected herplannen: SidemenuItem[] = [
    {
      title: getStatusLabel('vervolgopname'),
      link: '/planning/plannable-reports?planning_status=vervolgopname&filter=exclude_ves',
      icon: {
        key: 'youtube_searched_for',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles) || this.user.hasSkill([SkillTypes.Vervolgafspraak])),
    },
    {
      title: getStatusLabel('aanvullend_onderzoek'),
      link: '/planning/plannable-reports?planning_status=aanvullend_onderzoek',
      icon: {
        key: 'zoom_in',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles) || this.user.hasSkill([SkillTypes.Aanvullend_onderzoek])),
    },
    {
      title: getStatusLabel('nader_onderzoek'),
      link: '/planning/plannable-reports?planning_status=nader_onderzoek',
      icon: {
        key: 'zoom_out_map',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles) || this.user.hasSkill([SkillTypes.Nader_Onderzoek])),
    },
    {
      title: getStatusLabel('heropname'),
      link: '/planning/plannable-reports?planning_status=heropname',
      icon: {
        key: 'zoom_out',
      },
      visible: (this.user.hasRole(this.userRoles.ManagerRoles) || this.user.hasSkill([SkillTypes.Heropname])),
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected get hasVisibleItems(): boolean {
    return !! this.items.filter((item: SidemenuItem): boolean => !! item.visible).length;
  }

  protected goToRoute() {
    this.$router.push({ name: 'organization-availability', params: { readOnly: 'true' } });
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected get user(): User {
    return this.$store.state.Auth;
  }

  protected get canListProjects(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.PROJECTS_LIST);
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Planning' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
